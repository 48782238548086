<template>
  <p :id="blockId" v-html="blockHtml"></p>
</template>

<script>
import contentBlock from "@/client/extensions/mixins/baseComponents/html/contentBlock";

export default {
  mixins: [contentBlock],
  props: {

  },
  data: function () {
    return {};
  },
  computed: {
    blockHtml() {
      return this?.block?.data?.text ? this.block.data.text : '';
    }
  },
};
</script>

<style scoped lang="scss"></style>
